<template>
  <v-container
    id="extended-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="far fa-newspaper"
      icon-small
      color="primary"
      :title="$t('pageTitles.News/ArticlesList')"
    >
      <template #subtitle>
        <div class="text-right text-h3">
          <app-btn
            color="success"
            class="px-2 ml-1"
            elevation="0"
            min-width="0"
            small
            to="/news/articles/new"
          >
            <v-icon
              small
              v-text="'fas fa-plus'"
            />
          </app-btn>
        </div>
      </template>

      <v-simple-table class="mt-4">
        <thead>
          <tr>
            <th>{{ $t('forms.label') }}</th>
            <th>{{ $t('forms.category') }}</th>
            <th>{{ $t('forms.published_at') }}</th>
            <th>{{ $t('forms.on_homepage') }}</th>
            <th class="text-right">
              {{ $t('forms.actions') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!articles || !articles.length">
          <tr>
            <td colspan="3">
              <i
                v-if="processing"
                class="fas fa-spin fa-spinner"
              ></i>
              <template v-else>
                {{ $t('forms.nodata') }}
              </template>
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            v-for="article in articles"
            :key="`news-article-list-${article.id}`"
          >
            <td>{{ article.label }}</td>
            <td>{{ article.category.label }}</td>
            <td>{{ article.published_at ? $moment(article.published_at).format('DD/MM/YYYY HH:mm') : $t('unscheduled') }}</td>
            <td>
              <span
                v-if="article.is_highlighted"
                class="success--text"
              >
                <i class="fas fa-check mr-3"></i> {{ $t('yes') }}
              </span>
              <span
                v-else
                class="error--text"
              >
                <i class="fas fa-times mr-3"></i> {{ $t('no') }}
              </span>
            </td>
            <td class="text-right table-actions">
              <v-btn
                color="success"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                :to="`/news/articles/${article.id}`"
              >
                <v-icon
                  small
                  v-text="'far fa-edit'"
                />
              </v-btn>
              <app-btn
                color="error"
                class="px-2 ml-2"
                elevation="0"
                min-width="0"
                small
                @click="initiateArticleDelete(article)"
              >
                <v-icon
                  small
                  v-text="'fas fa-trash'"
                />
              </app-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        v-if="articlesMeta && articlesMeta.last_page && articlesMeta.last_page > 1"
        v-model="currentPage"
        :length="articlesMeta.last_page"
        class="mt-4"
      ></v-pagination>
    </material-card>
    <v-dialog
      v-model="showDeletionDialog"
      width="300"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ $t('delete_confirm') }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="gray"
            text
            @click="closeDeletionDialog"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deleteArticle"
          >
            {{ $t('delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import serverError from '../../mixins/serverError';
export default {
  name: 'NewsArticlesList',

  mixins: [serverError],

  data: () => ({
    articles: [],
    showDeletionDialog: false,
    articleToDelete: null,
    perPage: 15,
    currentPage: 1,
  }),

  computed: {
    ...mapGetters({
      newsArticles: 'news/getArticles',
      processing: 'global/getProcessing',
      articlesMeta: 'news/getArticlesMeta',
    }),
  },

  watch: {
    currentPage() {
      this.refreshData();
    },
  },

  created() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      this.articles = [];
      const params = {
        perPage: this.perPage,
        page: this.currentPage,
      };
      this.$store.dispatch('news/fetchArticles', { params })
        .then(() => {
          this.articles = [...this.newsArticles];
        });
    },
    initiateArticleDelete(article) {
      this.articleToDelete = article.id;
      this.showDeletionDialog = true;
    },
    closeDeletionDialog() {
      this.articleToDelete = null;
      this.showDeletionDialog = false;
    },
    deleteArticle() {
      this.$store.dispatch('news/deleteArticle', this.articleToDelete)
        .then(() => {
          this.$toasted.success(this.$t('item_deleted'));
          this.refreshData();
        })
        .catch(error => this.displayErrors(error))
        .finally(() => this.closeDeletionDialog());
    },
  },
}
</script>

<style lang="scss">
.fa-arrows-alt {
  cursor: grab;
}
</style>
